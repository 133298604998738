import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-arvada-colorado.png'
import image0 from "../../images/cities/scale-model-of-golden-visitors-center-in-arvada-colorado.png"
import image1 from "../../images/cities/scale-model-of-golden-history-park-in-arvada-colorado.png"
import image2 from "../../images/cities/scale-model-of-colorado-railroad-museum-in-arvada-colorado.png"
import image3 from "../../images/cities/scale-model-of-lakeside-amusement-park-in-arvada-colorado.png"
import image4 from "../../images/cities/scale-model-of-downtown-golden-in-arvada-colorado.png"
import image5 from "../../images/cities/scale-model-of-colorado-school-of-mines-geology-museum-in-arvada-colorado.png"
import image6 from "../../images/cities/scale-model-of-berkeley-lake-park-in-arvada-colorado.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Arvada'
            state='Colorado'
            image={image}
            lat='39.8027644'
            lon='-105.0874842'
            attractions={ [{"name": "Golden Visitors Center", "vicinity": "1010 Washington Ave, Golden", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 39.756931, "lng": -105.2233281}, {"name": "Golden History Park", "vicinity": "1020 11th St, Golden", "types": ["museum", "park", "point_of_interest", "establishment"], "lat": 39.7547055, "lng": -105.22569729999998}, {"name": "Colorado Railroad Museum", "vicinity": "17155 W 44th Ave, Golden", "types": ["museum", "point_of_interest", "establishment"], "lat": 39.7713848, "lng": -105.19336909999998}, {"name": "Lakeside Amusement Park", "vicinity": "4601 Sheridan Boulevard, Denver", "types": ["amusement_park", "point_of_interest", "establishment"], "lat": 39.7783831, "lng": -105.05375559999999}, {"name": "Downtown Golden", "vicinity": "Washington Ave, Golden", "types": ["point_of_interest", "establishment"], "lat": 39.7552901, "lng": -105.22127869999997}, {"name": "Colorado School of Mines Geology Museum", "vicinity": "1310 Maple St, Golden", "types": ["museum", "point_of_interest", "establishment"], "lat": 39.7518626, "lng": -105.22485159999997}, {"name": "Berkeley Lake Park", "vicinity": "4601 W 46th Ave, Denver", "types": ["park", "point_of_interest", "establishment"], "lat": 39.78131, "lng": -105.04784310000002}] }
            attractionImages={ {"Golden Visitors Center":image0,"Golden History Park":image1,"Colorado Railroad Museum":image2,"Lakeside Amusement Park":image3,"Downtown Golden":image4,"Colorado School of Mines Geology Museum":image5,"Berkeley Lake Park":image6,} }
       />);
  }
}